  $(".openbtn").click(function () {//ボタンがクリックされたら
    $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
      $("#g-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
  });
  
  $("#g-nav a").click(function () {//ナビゲーションのリンクがクリックされたら
      $(".openbtn").removeClass('active');//ボタンの activeクラスを除去し
      $("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
  });

  $(function () {
    $('#casemodal').click(function(){
        $('#modalArea').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea').fadeOut();
    });
  });

  $(function () {
    $('#casemodal2').click(function(){
        $('#modalArea2').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea2').fadeOut();
    });
  });

  $(function () {
    $('#casemodal3').click(function(){
        $('#modalArea3').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea3').fadeOut();
    });
  });

  $(function () {
    $('#casemodal4').click(function(){
        $('#modalArea4').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea4').fadeOut();
    });
  });

  $(function () {
    $('#casemodal5').click(function(){
        $('#modalArea5').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea5').fadeOut();
    });
  });

  $(function () {
    $('#casemodal6').click(function(){
        $('#modalArea6').fadeIn();
    });
    $('#closeModal , #modalBg').click(function(){
      $('#modalArea6').fadeOut();
    });
  });


  $(function(){
    $('.page-link').click(function(){
      // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
      var adjust = -40;
      // スクロールの速度
      var speed = 250; // ミリ秒
      // アンカーの値取得
      var href= $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を調整
      var position = target.offset().top + adjust;
      // スムーススクロール
      $('body,html').animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });
